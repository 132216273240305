<template>
  <Button
    id="offcanvas-menu-toggle"
    :icon="SVG_ICONS.MENU"
    :size="BUTTON_SIZE.BASE"
    :theme="BUTTON_THEME.TRANSPARENT"
    :title="modelValue ? t('btn.closeMenu') : t('btn.openMenu')"
    class="button-header lg:hidden"
    @click="$emit('update:modelValue', !modelValue)"
  />
</template>

<script setup lang="ts">
interface Props {
  modelValue: boolean
}

defineProps<Props>()

defineEmits(['update:modelValue'])

defineOptions({
  name: 'PageHeaderNavigationToggle',
})

const { t } = useI18n()
</script>

<i18n>
de:
  btn:
    openMenu: "Menü anzeigen"
    closeMenu: "Menü verstecken"
es:
  btn:
    openMenu: "Mostrar menú"
    closeMenu: "Ocultar menú"
</i18n>
