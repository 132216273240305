<template>
  <NuxtLink :to="link" :title="cmsConfig?.name" class="inline-flex">
    <div
      v-if="cmsConfig?.svgLogo"
      class="h-full [&>svg]:h-full [&>svg]:w-auto"
      v-html="cmsConfig?.svgLogo"
    />

    <NuxtImg
      v-else-if="logo"
      id="logo"
      :alt="cmsConfig?.name"
      :src="logo?.src"
      height="96"
      :preload="preload"
      :loading="loading"
      :format="format"
      class="h-full w-auto"
    />

    <div v-else>{{ cmsConfig?.name }}</div>
  </NuxtLink>
</template>

<script setup lang="ts">
defineOptions({
  name: 'PageLogo',
})

interface Props {
  link?: string
  preload?: boolean
  loading?: ImageLoading
  format?: ImageFileFormat
}

withDefaults(defineProps<Props>(), {
  link: '/',
  preload: true,
  loading: IMAGE_LOADING.LAZY,
  format: IMAGE_FILE_FORMAT.WEBP,
})

const cmsConfig = useCmsConfig()
const logo = cmsConfig.value?.logo
  ? getImage(cmsConfig.value, 'logo', cmsConfig.value?.name)
  : null
</script>
