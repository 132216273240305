<template>
  <div v-if="messages.length" class="absolute top-10 z-50 w-full space-y-1.5">
    <Message v-for="message in messages" :key="message.id" :message />
  </div>
</template>

<script lang="ts" setup>
const { messages, add } = useMessage()
const route = useRoute()
const router = useRouter()
const { $sanitizeHTML } = useNuxtApp()

watch(
  () => route.fullPath,
  () => {
    const hasError = !!route.query.error || !!route.query['error-desc']
    if (hasError) {
      add({
        title: $sanitizeHTML(route.query.error),
        message: $sanitizeHTML(route.query['error-desc']),
        type: MESSAGE_TYPE.MODAL,
        severity: MESSAGE_SEVERITY.ERROR,
      })

      // Only replace the route if necessary to avoid unnecessary history entries.
      const { error, 'error-desc': errorDesc, ...restQuery } = route.query
      if (error || errorDesc) {
        router.replace({ query: restQuery })
      }
    }
  },
  { immediate: true }
)

defineOptions({
  name: 'MessageController',
})
</script>
