<template>
  <div
    class="bg-header/90 px-safe fixed left-0 top-0 z-30 h-[100dvh] w-80"
    :class="{
      'transition-transform duration-300 ease-in-out': isAnimating,
      '-translate-x-full': !isOpen,
      'lg:static lg:left-auto lg:top-auto lg:flex lg:h-20 lg:w-auto lg:!translate-x-0 lg:space-x-8 lg:bg-transparent lg:transition-none': true,
    }"
  >
    <div class="grid h-[100dvh] grid-rows-[auto,1fr] lg:contents">
      <div class="flex h-14 items-center px-3 lg:hidden">
        <Button
          :icon="SVG_ICONS.CLOSE"
          :theme="BUTTON_THEME.TRANSPARENT"
          class="button-header lg:hidden"
          @click="hideMenu"
        />

        <PageLogo class="h-8 lg:h-12" />
      </div>

      <div class="scrollbar-themed overflow-y-auto lg:!contents">
        <MenuItemLevel1
          v-for="(menuItem, i) in menuItems"
          :key="i"
          :indent-level1="!!hasIcons"
          v-bind="menuItem"
        />
      </div>
    </div>
  </div>
  <BackgroundBlur :visible="isOpen" @click.self="hideMenu" />
</template>

<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'

interface Props {
  open: boolean
}

const props = defineProps<Props>()
const isOpen = ref(false)

const route = useRoute()
const { menuItems, hideMenu } = useMenu()

const isAnimating = ref(false)
const hasIcons = computed(() =>
  menuItems.value?.some(({ icon }: { icon: string }) => icon)
)

watch(
  () => props.open,
  async () => {
    isAnimating.value = true
    isOpen.value = props.open
    await promiseTimeout(300)
    isAnimating.value = false
  },
  {
    immediate: true,
  }
)

watch(() => route.path, hideMenu)

defineOptions({
  name: 'PageNavigation',
})
</script>
